<template>
  <div id="view">
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'CoreView',
  }
</script>

<style lang="sass">
  #view
    padding-bottom: 72px
</style>
